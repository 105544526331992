import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'amfe-flexible'
import '@/permission'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import 'vant/es/notify/style'
import 'vant/es/image-preview/style'
import { Button, NavBar, ConfigProvider, Field, CellGroup, Checkbox, CheckboxGroup, Uploader,
  DatetimePicker, Popup, Picker, Tabbar, TabbarItem, Tab, Tabs, PullRefresh, List, Icon, Switch,
  Swipe, SwipeItem, Step, Steps, SwipeCell, RadioGroup, Radio, Overlay, Loading } from 'vant'

const app = createApp(App)
    .use(store).use(router).use(Button).use(NavBar).use(ConfigProvider).use(Field)
  .use(CellGroup).use(Checkbox).use(CheckboxGroup).use(Uploader).use(DatetimePicker).use(Popup)
  .use(Picker).use(Tabbar).use(TabbarItem).use(Tab).use(Tabs).use(PullRefresh).use(List).use(Icon)
  .use(Switch).use(Swipe).use(SwipeItem).use(Step)
    .use(Steps).use(SwipeCell).use(RadioGroup).use(Radio).use(Overlay).use(Loading)
app.mount('#app')
