// @ is an alias to /src
import store from '@/store';
import { computed } from 'vue';
import { onMounted } from 'vue';
import qrcode from '@/assets/qrcode.jpeg';
import rightimg from '@/assets/rightimg.png';
import word from '@/assets/word.png';
import ios from '@/assets/ios-black.png';
import android from '@/assets/android-black.png';
import code from '@/assets/code-small.png';
import quan from '@/assets/quan.png';
import ContactTip from '@/components/ContactTip';
import { ref } from 'vue';
import { saveAs } from 'file-saver';
export default {
  name: 'HomeView',
  components: {
    ContactTip
  },

  setup() {
    const user = computed(() => {
      return store.getters.getUserInfo;
    });
    onMounted(() => {});

    const bigImg = () => {
      big.value = !big.value;
    };

    const downloadAndroid = () => {
      saveAs('https://www.yanruyu.love/download/androidRelease.apk', 'tangguo.apk');
    };

    const tips = ref();
    const big = ref(false);
    const show = ref(false);

    const moved = () => {
      show.value = false;
    };

    const move = () => {
      show.value = true;
    };

    onMounted(() => {
      const ua = navigator.userAgent;
      const ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
            isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
            isAndroid = ua.match(/(Android)\s+([\d.]+)/);

      if (isIphone || isAndroid) {
        window.location.href = 'https://www.yanruyu.love/down-page';
      }
    });

    const downIos = () => {
      window.location.href = 'itms-services://?action=download-manifest&url=https://www.yanruyu.love/download/manifest.plist';
    };

    return {
      user,
      qrcode,
      rightimg,
      ios,
      android,
      tips,
      bigImg,
      big,
      downloadAndroid,
      quan,
      word,
      code,
      show,
      moved,
      move,
      downIos
    };
  }

};