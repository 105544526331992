import request from '@/utils/request'

const api = {
  UploadLibraryImage: 'dragon/upload/uploadLibraryImage',
  GetThresholdFlagSwitch: 'dragon/app/getThresholdFlagSwitch',
  GetAllVirtualProduct: 'dragon/product/getAllVirtualProduct',
  Auth: 'web-api/index/auth',
  PunishList: 'web-api/index/punishList',
  CollectInfo: '/web-api/index/collectInfo'
}

/**
 * @param parameter
 * @returns {*}
 */
export function uploadLibraryImage (file) {
  return request({
    url: api.UploadLibraryImage,
    method: 'post',
    data: {
      file
    },
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function getThresholdFlagSwitch () {
  return request({
    url: api.GetThresholdFlagSwitch,
    method: 'get'
  })
}

export function punishList () {
  return request({
    url: api.PunishList,
    method: 'get'
  })
}

export function collectInfo (param) {
  return request({
    url: api.CollectInfo,
    method: 'get',
    params: param
  })
}
