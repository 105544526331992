import router from './router'
// import store from '@/store'

// 无需登录能进入的页面
const loginAuth = [
]
// const authPage = [
// ]

router.beforeEach((to, from, next) => {
  /* has token */
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  if (loginAuth.includes(to.name)) {
    next()
  } else {
    // const token = localStorage.getItem('ACCESS_TOKEN')
    // if (token !== null) {
    //   const user = store.getters.getUserInfo
    //   if (user.incId === undefined) {
    //     store.dispatch('RefreshInfo').then(() => {
    //       if (to.name === 'LoginGuide' || to.name === 'Login') {
    //         next({ name: 'Home' })
    //       } else {
    //         next()
    //       }
    //     })
    //   } else {
    //     const switchs = store.getters.getSwitch
    //     if (to.name === 'LoginGuide' || to.name === 'Login') {
    //       next({ name: 'Home' })
    //     } else if (switchs === 0 && user.thresholdFlag === 0 && !authPage.includes(to.name)) {
    //       next({ name: 'ThresholdPage' })
    //     } else {
    //       next()
    //     }
    //   }
    // } else {
    //   if (to.name !== 'LoginGuide') {
    //     next({ name: 'LoginGuide' })
    //   } else {
    //     next()
    //   }
    // }
    next()
  }
})
